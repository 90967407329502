// src/templates/post.js
import React from "react"
import "../styles/post.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Post({ pageContext }) {
  const { title, eventDate, image } = pageContext.post
  // const description = pageContext.post.description.description;
  const body = pageContext.post.body.childMarkdownRemark.html

  return (
    <Layout>
      <Seo title={title} />
      <div className="postpage">
        <div className="postpage__wrapper">
          <div className="postpage__header">
            <p className="postpage__date">{eventDate}</p>
            <h1>{title}</h1>
          </div>
          {/* <img src={image.file.url} className="post-image" alt="post-cover"></img> */}
          <div dangerouslySetInnerHTML={{ __html: body }} className="postpage__body" />
        </div>
      </div>
    </Layout>
  )
}
